<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="147.25 86.719 188 118"
    enable-background="new 147.25 86.719 188 118"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        x="-3.25"
        y="82.438"
        display="inline"
        fill="#333333"
        width="479.5"
        height="130"
      />
    </g>
    <path
      fill="none"
      stroke="#FFFFFF"
      stroke-width="16"
      stroke-miterlimit="10"
      d="M-648.75-175.281"
    />
    <path
      fill="#337B9B"
      d="M270.213,90.828l-118.668-0.065l55.705,54.956l-55.705,54.956l118.796-0.065l59.909-54.825L270.213,90.828z"
    />
    <g>
      <path
        fill="#FFFFFF"
        d="M198.007,110.863c0-1.464,0.447-2.7,1.343-3.709c0.895-1.008,2.221-1.513,3.978-1.513
		s3.091,0.505,4.002,1.513c0.91,1.009,1.366,2.245,1.366,3.709s-0.456,2.685-1.366,3.66c-0.911,0.977-2.245,1.464-4.002,1.464
		s-3.083-0.487-3.978-1.464C198.454,113.547,198.007,112.326,198.007,110.863z M207.769,177.675h-9.029v-52.806h9.029V177.675z"
      />
      <path
        fill="#FFFFFF"
        d="M230.755,124.869l0.293,6.638c4.034-5.076,9.305-7.614,15.813-7.614c11.159,0,16.788,6.296,16.886,18.888
		v34.895h-9.028v-34.943c-0.033-3.807-0.903-6.621-2.611-8.443c-1.708-1.821-4.367-2.733-7.979-2.733
		c-2.928,0-5.498,0.781-7.711,2.343c-2.213,1.563-3.937,3.611-5.173,6.149v37.628h-9.028v-52.806H230.755z"
      />
      <path
        fill="#FFFFFF"
        d="M294.786,165.425l13.079-40.556h9.224l-18.936,52.806h-6.882l-19.131-52.806h9.224L294.786,165.425z"
      />
    </g>
  </svg>
</template>