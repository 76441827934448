
import { defineComponent, ref, watchEffect, watch, computed } from 'vue'
import UsersApi from '@/apis/rota-architect/users'
import useSelectOptions from '@/hooks/useSelectOptions'
import useToasts from '@/hooks/useToasts'
import parseErrorMap from '@/utils/parseErrorMap'
import { useRouter, useRoute } from 'vue-router'

// Components
import AdditionIcon from '@/components/icons/AdditionIcon.vue'
import FilterIcon from '@/components/icons/FilterIcon.vue'
import SuccessIcon from '@/components/icons/toast/SuccessIcon.vue'
import ErrorIcon from '@/components/icons/toast/ErrorIcon.vue'
import InvitedIcon from '@/components/icons/InvitedIcon.vue'

// Types
import { UserSuccessResponse } from '@/types/users'
import { PaginationConfig } from '@/types/base'

const REGISTERED_OPTIONS = [
      { label: 'Registered', value: 'yes' },
      { label: 'Invite pending', value: 'pending' },
      { label: 'Invitable', value: 'invitable' },
    ]

const ROLE_OPTIONS = [
  { value: 'admin', label: 'Admin' },
  { value: 'desk', label: 'Desk' },
  { value: 'staff', label: 'Staff' },
]

export default defineComponent({
  components: {
    AdditionIcon,
    FilterIcon,
    SuccessIcon,
    InvitedIcon,
    ErrorIcon,
  },

  setup () {
    const dispatch = useToasts()
    const router = useRouter()
    const route = useRoute()

    /**
     * Show/Hide filters toggle
     */
    const showingFilters = ref(true)

    const toggleShowingFilters = () => {
      showingFilters.value = !showingFilters.value
    }

    const toggleClasses = computed(() => {
      return showingFilters.value ? 'max-h-personnel-filters lg:max-h-72' : 'opacity-0 max-h-0 overflow-y-hidden xl:h-auto xl:max-h-full xl:opacity-100'
    })

    /**
     * Handle user fetching with pagination
     */
    const personnel = ref<UserSuccessResponse[]>([])
    const loadingPersonnel = ref(false)
    const paginationConfig = ref<PaginationConfig | null>(null)

    // depends on route.fullPath and I believe reruns whenever that changes, including initial page load.
    const fetchUsers = () => {
      if (route.name === 'personnel') {
        loadingPersonnel.value = true

        const queryString = route.fullPath.replace(route.path, '')
        UsersApi.all(queryString)
          .then((res) => {
            personnel.value = res.data.data
            paginationConfig.value = res.data.meta
          })
          .catch((err) => {
            dispatch.errorToast(parseErrorMap(err.response.data))
          })
          .finally(() => {
            loadingPersonnel.value = false
          })
      }
    }

    watchEffect(() => {
      fetchUsers()
    })

    const handlePagination = (page: number) => {
      router.replace({
        query: {
          ...route.query,
          page,
        },
      })
    }

    /**
     * Table data
     */
    const rows = computed(() => {
      if (!personnel.value.length) return []

      return personnel.value.map((user: UserSuccessResponse) => ({
        id: user.id,
        name: user.name,
        primary_rota: user.primary_rota?.label,
        sub_grade: user.sub_grade,
        contract: user.contract?.label,
        phone: user.phone,
        email: user.email,
        registered: user.registered,
        active_invites: user.active_invites,
      }))
    })

    /**
     * Filtering an Search
     */
    const { 
      options: subGradeOptions, 
      loading: loadingSubGradeOptions,
    } = useSelectOptions('SubGrade')

    const { 
      options: rotaOptions, 
      loading: loadingRotaOptions,
    } = useSelectOptions('Rota')

    const searchValue = ref(route.query?.search || '')
    const subGradeFilter = ref(route.query?.sub_grade || '')
    const siteFilter = ref(route.query?.site || '')
    const rotaFilter = ref(route.query?.rota || '')
    const roleFilter = ref(route.query?.role || '')
    const registeredFilter = ref(route.query?.registered || '')

    const handleSearch = (term: string) => {
      searchValue.value = term
    }

    // watch for filter changes and update path query string to reflect
    watch(
      [searchValue, subGradeFilter, siteFilter, rotaFilter, registeredFilter, roleFilter],
      () => {
        if (route.name === 'personnel') {
          const searchQuery = searchValue.value
            ? { search: searchValue.value }
            : {}

          const subGradeQuery = subGradeFilter.value
            ? { sub_grade: subGradeFilter.value } 
            : {}

          const roleQuery = roleFilter.value
            ? { role: roleFilter.value }
            : {}

          const rotaQuery = rotaFilter.value
            ? { rota: rotaFilter.value } 
            : {}

          const registeredQuery = registeredFilter.value
            ? { registered: registeredFilter.value } 
            : {}

          // page is notably missing as should be reset if filter/search updates
          router.replace({ 
            query: {
              ...searchQuery,
              ...subGradeQuery,
              ...roleQuery,
              ...rotaQuery,
              ...registeredQuery,
            },
          })
        }
      },
    )

    return {
      showingFilters,
      toggleShowingFilters,
      toggleClasses,
      columns: [
        { name: 'name', label:  'Name' },
        { name: 'primary_rota', label: 'Primary Rota', responsive: 'hidden md:table-cell' },
        { name: 'sub_grade', label: 'Grade', responsive: 'hidden sm:table-cell' },
        { name: 'contract', label: 'Contract', responsive: 'hidden xxl:table-cell' },
        { name: 'phone', label: 'Phone', responsive: 'hidden xl:table-cell' },
        { name: 'email', label: 'Email', responsive: 'hidden xxl:table-cell' },
        { name: 'registered', label: 'Registered', responsive: 'hidden xxl:table-cell', align: 'center' },
      ],
      rows: rows,
      loadingPersonnel,
      paginationConfig,
      handlePagination,
      handleSearch,
      searchValue,
      siteFilter,
      roleFilter,
      rotaFilter,
      subGradeFilter,
      registeredFilter,
      subGradeOptions,
      loadingSubGradeOptions,
      roleOptions: ROLE_OPTIONS,
      rotaOptions,
      loadingRotaOptions,
      registeredOptions: REGISTERED_OPTIONS,
    }
  },
})
